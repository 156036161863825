import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { CircularProgress, Button, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { isLoading, error, addRoom, editRoom } from "./roomSlice";
import { WindowList } from "../windows/WindowList";
import { token } from "../users/usersSlice";
import { shouldRedirect, resetRedirect } from "../redirect/redirectSlice";
import { Redirect } from "react-router-dom";
import { ItemList } from "../items/ItemList";

export const validForm = (fieldData) => {
  return fieldData.name;
};

export const Room = (props) => {
  const { id } = props.match.params;
  const { edit } = props;

  const tokenVal = useSelector(token);
  const shouldRedirectVal = useSelector(shouldRedirect);
  const dispatch = useDispatch();

  const loading = useSelector(isLoading);
  const hasError = useSelector(error);

  const room = useSelector((state) => state.rooms.roomData[id]);

  const [fieldData, setFieldData] = useState({});
  const selectedInput = useRef();

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  });

  useEffect(() => {
    return () => {
      dispatch(resetRedirect()); // reset redirect on unmount
    };
  }, [shouldRedirectVal, dispatch]);

  const submitRoom = (e) => {
    e.preventDefault();
    if (edit) {
      dispatch(editRoom({ tokenVal, id, room: fieldData }));
    } else {
      dispatch(addRoom({ tokenVal, id, room: fieldData }));
    }
  };

  if (shouldRedirectVal === "project") {
    return <Redirect to={`/Project/${id}`} />;
  }

  let renderComponent = "";

  if (loading === "pending") {
    renderComponent = <CircularProgress />;
  } else {
    renderComponent = (
      <div>
        <h2>{edit ? room.name : "New Room"}</h2>
        {hasError && <div>Something went wrong!</div>}
        <form autoComplete="off" onSubmit={submitRoom}>
          <div>
            <TextField
              inputRef={selectedInput}
              required
              label="Room name"
              onChange={(e) =>
                setFieldData({ ...fieldData, name: e.target.value })
              }
            ></TextField>
          </div>
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              aria-label={edit ? "Update Room" : "Add Room"}
              disabled={!validForm(fieldData)}
            >
              {edit ? "Update Room" : "Add Room"}
            </Button>
          </div>
        </form>
        {edit && (
          <>
            <ItemList itemIds={room.items} />
            <Button
              color="primary"
              variant="contained"
              aria-label="Add Items"
              component={Link}
              to={`/search?roomId=${id}`}
            >
              Add Items
            </Button>
            <WindowList windowIds={room.windows} />
            <Button
              component={Link}
              to={`/window/add/${id}`}
              color="primary"
              variant="contained"
              aria-label="Add Window"
            >
              Add Window
            </Button>
          </>
        )}
      </div>
    );
  }

  return renderComponent;
};
