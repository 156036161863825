import { TextField, Button } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import styles from "./Register.module.css";

export const validForm = (fieldData) => {
  return (
    fieldData.first_name &&
    fieldData.last_name &&
    fieldData.email &&
    fieldData.password &&
    fieldData.password === fieldData.password2
  );
};

export function Register() {
  const [fieldData, setFieldData] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const selectedInput = useRef();

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  }, []);

  const createUser = async (user) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/createUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }).then((res) => {
        if (res.status === 201) {
          setSuccess(true);
        } else {
          setError(true);
        }
      });
    } catch (error) {
      setError(true);
    }
  };

  const register = (e) => {
    e.preventDefault();

    createUser(fieldData);
  };

  return success ? (
    <p>
      You have successfully registered. Your account will need to be approved by
      an administrator before you can log in.
    </p>
  ) : (
    <div>
      <h1>Register</h1>
      <form autoComplete="off" onSubmit={register}>
        <div className={styles.row}>
          <TextField
            required
            label="First Name"
            onChange={(e) =>
              setFieldData({ ...fieldData, first_name: e.target.value })
            }
            inputRef={selectedInput}
          ></TextField>
        </div>
        <div className={styles.row}>
          <TextField
            required
            label="Last Name"
            onChange={(e) =>
              setFieldData({ ...fieldData, last_name: e.target.value })
            }
          ></TextField>
        </div>
        <div className={styles.row}>
          <TextField
            required
            label="Email"
            onChange={(e) =>
              setFieldData({ ...fieldData, email: e.target.value })
            }
          ></TextField>
        </div>
        <div className={styles.row}>
          <TextField
            required
            type="password"
            label="Choose a Password"
            onChange={(e) =>
              setFieldData({ ...fieldData, password: e.target.value })
            }
          ></TextField>
        </div>
        <div className={styles.row}>
          <TextField
            required
            type="password"
            label="Confirm Password"
            onChange={(e) =>
              setFieldData({ ...fieldData, password2: e.target.value })
            }
          ></TextField>
        </div>
        <div className={styles.row}>
          <TextField
            id="outlined-multiline-static"
            label="Notes"
            multiline
            rows={4}
            placeholder="Notes for the Stride Design team"
            variant="outlined"
            onChange={(e) =>
              setFieldData({ ...fieldData, notes: e.target.value })
            }
          />
        </div>
        <div className={styles.row}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            aria-label="Register"
            disabled={!validForm(fieldData)}
          >
            Register
          </Button>
        </div>
        {error && <p>An error occurred</p>}
      </form>
    </div>
  );
}
