import React, { useState, useRef, useEffect } from "react";
import { Button, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { token } from "../users/usersSlice";
import styles from "./Projects.module.css";
import { addProject } from "./projectsSlice";
import { Redirect } from "react-router-dom";
import { shouldRedirect, resetRedirect } from "../redirect/redirectSlice";

export const validForm = (fieldData) => {
  const requiredFields =
    fieldData.name &&
    fieldData.street &&
    fieldData.number &&
    fieldData.city &&
    fieldData.postalCode;
  return requiredFields;
};

export const AddProject = () => {
  const tokenVal = useSelector(token);
  const shouldRedirectVal = useSelector(shouldRedirect);
  const dispatch = useDispatch();
  const selectedInput = useRef();

  const [fieldData, setFieldData] = useState({});

  const submitNewProject = (e) => {
    e.preventDefault();
    dispatch(addProject({ tokenVal, addedProject: fieldData }));
  };

  useEffect(() => {
    return () => {
      dispatch(resetRedirect()); // reset redirect on unmount
    };
  }, [shouldRedirectVal, dispatch]);

  if (shouldRedirectVal === "projectList") {
    return <Redirect to={"/Projects"} />;
  }

  const renderComponent = (
    <div>
      <h2>New Project</h2>
      <form autoComplete="off" onSubmit={submitNewProject}>
        <div className={styles.row}>
          <TextField
            inputRef={selectedInput}
            required
            label="Project name"
            onChange={(e) =>
              setFieldData({ ...fieldData, name: e.target.value })
            }
          ></TextField>
        </div>

        <h3>Address</h3>

        <div className={styles.row}>
          <TextField
            inputRef={selectedInput}
            required
            label="Number"
            onChange={(e) =>
              setFieldData({ ...fieldData, number: e.target.value })
            }
          ></TextField>
        </div>

        <div className={styles.row}></div>
        <TextField
          inputRef={selectedInput}
          required
          label="Street"
          onChange={(e) =>
            setFieldData({ ...fieldData, street: e.target.value })
          }
        ></TextField>

        <div className={styles.row}>
          <TextField
            inputRef={selectedInput}
            label="Unit"
            onChange={(e) =>
              setFieldData({ ...fieldData, unit: e.target.value })
            }
          ></TextField>
        </div>

        <div className={styles.row}>
          <TextField
            inputRef={selectedInput}
            required
            label="City"
            onChange={(e) =>
              setFieldData({ ...fieldData, city: e.target.value })
            }
          ></TextField>
        </div>

        <div className={styles.row}>
          <TextField
            inputRef={selectedInput}
            required
            label="Postal Code"
            onChange={(e) =>
              setFieldData({ ...fieldData, postalCode: e.target.value })
            }
          ></TextField>
        </div>

        <div className={styles.row}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            aria-label="Create Project"
            disabled={!validForm(fieldData)}
          >
            Create Project
          </Button>
        </div>
      </form>
    </div>
  );

  return renderComponent;
};
