import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const searchProducts = createAsyncThunk(
  "search/searchProducts",
  async (data, { dispatch }) => {
    const { tokenVal, searchCriteria } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const search = await fetch(
        `${process.env.REACT_APP_API_URL}/searchProducts?query=${searchCriteria.query}`,
        {
          headers: headers,
        }
      ).then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }
        }
      });

      return search;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    searchData: {},
    loading: "idle",
    error: null,
  },
  extraReducers: {
    [searchProducts.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.searchData = action.payload;
    },
    [searchProducts.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [searchProducts.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const searchResults = (state) => state.search.searchData;
export const isLoading = (state) => state.search.loading;
export const error = (state) => state.search.error;

export default searchSlice.reducer;
