import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getProductItemDetails = createAsyncThunk(
  "productItems/getProductItemDetails",
  async (data, { dispatch }) => {
    const { tokenVal, id } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const productItem = await fetch(
        `${process.env.REACT_APP_API_URL}/getCasedItem/${id}`,
        {
          headers: headers,
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("getProductItemDetails failed");
        } else {
          return res.json();
        }
      });

      return productItem;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const addItemToRoom = createAsyncThunk(
  "productItems/addItemToRoom",
  async (data, { dispatch }) => {
    const { tokenVal, addedItem } = data;

    const item = {
      cased_item_id: addedItem.item_id,
      room_id: addedItem.room_id,
      quantity: addedItem.quantity,
    };

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const newItem = await fetch(
        `${process.env.REACT_APP_API_URL}/addCasedItemToRoom`,
        {
          method: "POST",
          mode: "cors",
          headers: headers,
          body: JSON.stringify(item),
        }
      ).then((res) => {
        if (res.status !== 201) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("addItemToRoom failed");
        } else {
          return res.json();
        }
      });

      return {
        project_product_id: newItem.insertId,
        product_id: item.cased_item_id,
        custom_product_id: null,
        quantity: addedItem.quantity,
        room_id: addedItem.room_id,
        window_id: null,
        address_id: null,
        name: addedItem.name,
        description: addedItem.description,
      };
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const addItemToWindow = createAsyncThunk(
  "productItems/addItemToWindow",
  async (data, { dispatch }) => {
    const { tokenVal, addedItem } = data;

    const item = {
      cased_item_id: addedItem.item_id,
      window_id: addedItem.window_id,
      quantity: addedItem.quantity,
    };

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const newItem = await fetch(
        `${process.env.REACT_APP_API_URL}/addCasedItemToWindow`,
        {
          method: "POST",
          mode: "cors",
          headers: headers,
          body: JSON.stringify(item),
        }
      ).then((res) => {
        if (res.status !== 201) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("addItemToWindow failed");
        } else {
          return res.json();
        }
      });

      return {
        project_product_id: newItem.insertId,
        product_id: item.cased_item_id,
        custom_product_id: null,
        quantity: addedItem.quantity,
        room_id: null,
        window_id: addedItem.window_id,
        address_id: null,
        name: addedItem.name,
        description: addedItem.description,
      };
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const productItemsSlice = createSlice({
  name: "productItems",
  initialState: {
    productItem: {},
    loading: "idle",
    error: null,
  },
  extraReducers: {
    [getProductItemDetails.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.productItem = action.payload || {};
    },
    [getProductItemDetails.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getProductItemDetails.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
  },
});

export const productItem = (state) => state.productItems.productItem;
export const isLoading = (state) => state.productItems.loading;
export const error = (state) => state.productItems.error;

export default productItemsSlice.reducer;
