import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import projectsReducer from "../features/projects/projectsSlice";
import usersReducer from "../features/users/usersSlice";
import roomsReducer from "../features/rooms/roomSlice";
import windowsReducer from "../features/windows/windowSlice";
import redirectReducer from "../features/redirect/redirectSlice";
import projectItemsReducer from "../features/items/projectItemSlice";
import searchReducer from "../features/search/searchSlice";
import productItemsReducer from "../features/items/productItemSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const appReducer = combineReducers({
  users: usersReducer,
  projects: projectsReducer,
  rooms: roomsReducer,
  windows: windowsReducer,
  redirect: redirectReducer,
  projectItems: projectItemsReducer,
  search: searchReducer,
  productItems: productItemsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "users/logout") {
    storage.removeItem("persist:root");
    state = undefined;
  }

  if (action.type === "invalidToken") {
    storage.removeItem("persist:root");
    state = {
      users: { error: "Your session has expired. Log in again to continue." },
    };
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
