import React, { useState, useRef, useEffect } from "react";
import { CircularProgress, Button, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { isLoading, error, addWindow, editWindow } from "./windowSlice";
import { token } from "../users/usersSlice";
import { shouldRedirect, resetRedirect } from "../redirect/redirectSlice";
import { Redirect, Link } from "react-router-dom";
import { ItemList } from "../items/ItemList";

export const validForm = (fieldData) => {
  return fieldData.name;
};

export const Window = (props) => {
  const { id } = props.match.params;
  const { edit } = props;

  const tokenVal = useSelector(token);
  const shouldRedirectVal = useSelector(shouldRedirect);
  const dispatch = useDispatch();

  const loading = useSelector(isLoading);
  const hasError = useSelector(error);

  const roomWindow = useSelector((state) => state.windows.windowData[id]);

  const [fieldData, setFieldData] = useState({});
  const selectedInput = useRef();

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  });

  useEffect(() => {
    return () => {
      dispatch(resetRedirect()); // reset redirect on unmount
    };
  }, [shouldRedirectVal, dispatch]);

  const submitWindow = (e) => {
    e.preventDefault();

    if (edit) {
      dispatch(editWindow({ tokenVal, id, roomWindow: fieldData }));
    } else {
      dispatch(addWindow({ tokenVal, id, roomWindow: fieldData }));
    }
  };

  if (shouldRedirectVal === "room") {
    return <Redirect exact to={`/room/edit/${id}`} />;
  }

  let renderComponent = "";

  if (loading === "pending") {
    renderComponent = <CircularProgress />;
  } else {
    renderComponent = (
      <div>
        <h2>{edit ? roomWindow.name : "New Window"}</h2>
        {hasError && <div>Something went wrong!</div>}
        <form autoComplete="off" onSubmit={submitWindow}>
          <div>
            <TextField
              inputRef={selectedInput}
              required
              label="Window name"
              onChange={(e) =>
                setFieldData({ ...fieldData, name: e.target.value })
              }
            ></TextField>
          </div>
          {edit && (
            <>
              <ItemList itemIds={roomWindow.items} />
              <Button
                color="primary"
                variant="contained"
                aria-label="Add Items"
                component={Link}
                to={`/search?windowId=${id}`}
              >
                Add Items
              </Button>
            </>
          )}
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              aria-label={edit ? "Update Window" : "Add Window"}
              disabled={!validForm(fieldData)}
            >
              {edit ? "Update Window" : "Add Window"}
            </Button>
          </div>
        </form>
      </div>
    );
  }

  return renderComponent;
};
