import { createSlice } from "@reduxjs/toolkit";

const redirectSlice = createSlice({
  name: "redirect",
  initialState: {
    redirectTo: null,
  },
  reducers: {
    resetRedirect: (state) => {
      state.redirectTo = null;
    },
  },
  extraReducers: {
    "projects/addProject/fulfilled": (state, action) => {
      state.redirectTo = "projectList";
    },
    "rooms/addRoom/fulfilled": (state, action) => {
      state.redirectTo = "project";
    },
    "windows/addWindow/fulfilled": (state, action) => {
      state.redirectTo = "room";
    },
    "items/updateProjectCasedItem/fulfilled": (state, action) => {
      const redirectDestination = !action.payload.room_id ? "window" : "room";
      state.redirectTo = redirectDestination;
    },
    "productItems/addItemToRoom/fulfilled": (state, action) => {
      state.redirectTo = "search";
    },
    "productItems/addItemToWindow/fulfilled": (state, action) => {
      state.redirectTo = "search";
    },
  },
});

export const shouldRedirect = (state) => state.redirect.redirectTo;
export const { resetRedirect } = redirectSlice.actions;

export default redirectSlice.reducer;
