import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getProjectDetails } from "./projectsSlice";
import { RoomList } from "../rooms/RoomList";
import { token } from "../users/usersSlice";

export const Project = (props) => {
  const { id } = props.match.params;
  const tokenVal = useSelector(token);
  const dispatch = useDispatch();

  const project = useSelector((state) => state.projects.projectData[id]);

  useEffect(() => {
    dispatch(getProjectDetails({ tokenVal, id }));
  }, [dispatch, id, tokenVal]);

  const renderComponent = (
    <div>
      <h2>{project.name}</h2>
      {project.rooms && (
        <Button
          component={Link}
          to={`/checkout/${id}`}
          color="secondary"
          variant="contained"
          aria-label="checkout"
        >
          Checkout
        </Button>
      )}
      {project.rooms && <RoomList roomIds={project.rooms} />}

      <Button
        component={Link}
        to={`/room/add/${id}`}
        color="primary"
        variant="contained"
        aria-label="Add Rooms"
      >
        Add Room
      </Button>
    </div>
  );

  return renderComponent;
};
