import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { CircularProgress, Link } from "@material-ui/core";
import { useSelector } from "react-redux";
import { isLoading, itemList } from "./projectItemSlice";

export const ItemList = (props) => {
  const { itemIds } = props;
  const loading = useSelector(isLoading);
  const allItems = useSelector(itemList);

  const items = itemIds.map((itemId) => allItems[itemId]);

  const renderComponent =
    loading === "pending" ? (
      <CircularProgress />
    ) : (
      <div>
        <p>Items</p>
        {items &&
          items.map((item) => (
            <div key={item.project_product_id}>
              <Link
                component={RouterLink}
                to={`/projectItem/view/${item.project_product_id}`}
              >
                {item.name}
              </Link>
            </div>
          ))}
      </div>
    );

  return renderComponent;
};
