import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { CircularProgress, Link } from "@material-ui/core";
import { useSelector } from "react-redux";
import { isLoading, windowList } from "./windowSlice";

export const WindowList = (props) => {
  const { windowIds } = props;
  const loading = useSelector(isLoading);
  const roomWindows = useSelector(windowList);

  const windowsForRoom = windowIds.map((key) => roomWindows[key]);

  const renderComponent =
    loading === "pending" ? (
      <CircularProgress />
    ) : (
      <div>
        <p>Windows</p>
        {windowsForRoom &&
          windowsForRoom.map((w) => (
            <div key={w.window_id}>
              <Link component={RouterLink} to={`/window/edit/${w.window_id}`}>
                {w.name}
              </Link>
            </div>
          ))}
      </div>
    );

  return renderComponent;
};
