import { TextField, Button } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Login.module.css";
import { getAuthToken, token, authenticationError } from "../users/usersSlice";

export const validForm = (fieldData) => {
  return fieldData.email && fieldData.password;
};

export function Login() {
  const [fieldData, setFieldData] = useState({});
  const selectedInput = useRef();
  const dispatch = useDispatch();
  const authenticated = useSelector(token);
  const error = useSelector(authenticationError);

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  }, []);

  const login = (e) => {
    e.preventDefault();

    dispatch(getAuthToken({ ...fieldData }));
  };

  const renderComponent = !authenticated ? (
    <div>
      <h2>Test Login</h2>
      <form autoComplete="off" onSubmit={login}>
        <div className={styles.row}>
          <TextField
            required
            label="Email"
            onChange={(e) =>
              setFieldData({ ...fieldData, email: e.target.value })
            }
            inputRef={selectedInput}
          ></TextField>
        </div>
        <div className={styles.row}>
          <TextField
            required
            label="Password"
            type="password"
            onChange={(e) =>
              setFieldData({ ...fieldData, password: e.target.value })
            }
          ></TextField>
        </div>
        <div className={styles.row}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            aria-label="Login"
            disabled={!validForm(fieldData)}
          >
            Login
          </Button>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </form>
    </div>
  ) : (
    <Redirect to="/projects" />
  );

  return renderComponent;
}
