import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getAuthToken = createAsyncThunk(
  "users/getAuthToken",
  async (data) => {
    const headers = new Headers({
      "Content-Type": "application/json",
    });

    try {
      const token = await fetch(`${process.env.REACT_APP_API_URL}/authorize`, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((result) => {
          return result ?? Promise.reject;
        });

      return token;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    loading: "",
    error: null,
    authToken: null,
    user_id: null,
  },
  reducers: {
    logout: (state, action) => {},
  },
  extraReducers: {
    [getAuthToken.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.authToken = action.payload.accessToken;
      state.user_id = action.payload.user_id;
    },
    [getAuthToken.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getAuthToken.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = "Login failed. Check your username and password.";
    },
  },
});

export const { logout } = usersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const token = (state) => state.users.authToken;
export const authenticationError = (state) => state.users.error;
export const user_id = (state) => state.users.user_id;

export default usersSlice.reducer;
