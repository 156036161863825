import React, { useEffect } from "react";
import { Button, Link } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { projectList, getProjects } from "./projectsSlice";
import { token } from "../users/usersSlice";
import styles from "./Projects.module.css";
import { Link as RouterLink } from "react-router-dom";

export const ProjectList = (props) => {
  const tokenVal = useSelector(token);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects({ tokenVal }));
  }, [dispatch, tokenVal]);

  const projects = useSelector(projectList);

  const renderComponent = (
    <div>
      <h2>My Dashboard</h2>
      {Object.keys(projects).map((key) => (
        <div className={styles.row} key={`project-${key}`}>
          <Link
            component={RouterLink}
            to={`/project/${projects[key].project_id}`}
          >
            {projects[key].name}
          </Link>
        </div>
      ))}
      <Button
        component={RouterLink}
        to={`/project/add`}
        color="primary"
        variant="contained"
        aria-label="Add Project"
      >
        Add Project
      </Button>
      <Button
        component={Link}
        to={`/room/add/`}
        color="primary"
        variant="contained"
        aria-label="My Settings"
      >
        My Settings
      </Button>
    </div>
  );

  return renderComponent;
};
