import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Button,
  TextField,
  Container,
} from "@material-ui/core";
import { isLoading, updateProjectCasedItem } from "./projectItemSlice";
import { token } from "../users/usersSlice";
import { shouldRedirect, resetRedirect } from "../redirect/redirectSlice";
import { Redirect } from "react-router-dom";

export const validForm = (fieldData) => {
  return fieldData.name;
};

export const ProjectItemDetails = (props) => {
  const { id } = props.match.params;

  const loading = useSelector(isLoading);
  const item = useSelector((state) => state.projectItems.itemData[id]);
  const tokenVal = useSelector(token);
  const shouldRedirectVal = useSelector(shouldRedirect);

  const [fieldData, setFieldData] = useState({ quantity: item.quantity });

  const selectedInput = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  });

  useEffect(() => {
    return () => {
      dispatch(resetRedirect()); // reset redirect on unmount
    };
  }, [shouldRedirectVal, dispatch]);

  const saveItem = (e) => {
    e.preventDefault();
    dispatch(
      updateProjectCasedItem({
        tokenVal,
        updatedItemVal: { ...item, ...fieldData },
      })
    );
  };

  if (shouldRedirectVal === "room") {
    return <Redirect exact to={`/room/edit/${item.room_id}`} />;
  } else if (shouldRedirectVal === "window") {
    return <Redirect exact to={`/window/edit/${item.window_id}`} />;
  }

  const renderComponent =
    loading === "pending" ? (
      <CircularProgress />
    ) : (
      <div>
        <h2>{item.name}</h2>
        <span>{item.description}</span>
        <form autoComplete="off" onSubmit={saveItem}>
          <Container>
            <TextField
              variant="outlined"
              margin="normal"
              inputRef={selectedInput}
              label="Quantity"
              type="number"
              defaultValue={item.quantity}
              onChange={(e) =>
                setFieldData({ ...fieldData, quantity: e.target.value })
              }
              InputProps={{ inputProps: { min: 1 } }}
            />
          </Container>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            aria-label="Save Item"
          >
            Save Changes
          </Button>

          <Button
            variant="contained"
            color="secondary"
            aria-label="Delete Item"
          >
            Delete
          </Button>

          <Button variant="contained" color="primary" aria-label="Hide Item">
            Hide
          </Button>

          <Button
            variant="contained"
            color="primary"
            aria-label={"Add Item to Another Project"}
          >
            Add to Another Project
          </Button>
        </form>
      </div>
    );

  return renderComponent;
};
