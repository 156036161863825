import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { CircularProgress, Link } from "@material-ui/core";
import { useSelector } from "react-redux";
import { isLoading, roomList } from "./roomSlice";

export const RoomList = (props) => {
  const { roomIds } = props;

  const loading = useSelector(isLoading);
  const rooms = useSelector(roomList);

  const projectRooms = roomIds.map((key) => rooms[key]);

  const renderComponent =
    loading === "pending" ? (
      <CircularProgress />
    ) : (
      <div>
        <p>Rooms</p>
        {projectRooms &&
          projectRooms.map((room) => (
            <div key={room.room_id}>
              <Link component={RouterLink} to={`/room/edit/${room.room_id}`}>
                {room.name}
              </Link>
            </div>
          ))}
      </div>
    );

  return renderComponent;
};
