import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import { PrivateRoute } from "./PrivateRoute";
import { logout, token } from "../users/usersSlice";

import { ProjectList } from "../projects/ProjectList";
import { Project } from "../projects/Project";
import { AddProject } from "../projects/AddProject";
import { Room } from "../rooms/Room";
import { Window } from "../windows/Window";
import { Register } from "../register/Register";
import { Login } from "../login/Login";
import { ProjectItemDetails } from "../items/ProjectItemDetails";
import { ProductItemDetails } from "../items/ProductItemDetails";

import "./Routes.css";

export function Routes() {
  const dispatch = useDispatch();
  const authenticated = useSelector(token);

  const SearchContainer = lazy(() => import("../search/SearchContainer"));
  const Checkout = lazy(() => import("../checkout/Checkout"));

  return (
    <Router>
      <div>
        {authenticated && (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <div>
              <Button component={Link} to="/" color="primary">
                Projects
              </Button>
            </div>
            <div>
              <Button
                color="secondary"
                aria-label="Logout"
                onClick={() => {
                  dispatch(logout());
                }}
              >
                Logout
              </Button>
            </div>
          </Grid>
        )}
        <h1>Stride Design V1</h1>
        <Suspense fallback={<CircularProgress />}>
          <Switch>
            <Route exact path="/register">
              <Register />
            </Route>
            <PrivateRoute
              path="/projects"
              component={(props) => <ProjectList {...props} />}
            />
            <PrivateRoute
              path="/project/add"
              component={(props) => <AddProject {...props} />}
            />
            <PrivateRoute
              path="/project/:id"
              component={(props) => <Project {...props} />}
            />
            <PrivateRoute
              path="/room/add/:id"
              component={(props) => <Room {...props} edit={false} />}
            />
            <PrivateRoute
              path="/room/edit/:id"
              component={(props) => <Room {...props} edit={true} />}
            />
            <PrivateRoute
              path="/window/add/:id"
              component={(props) => <Window {...props} edit={false} />}
            />
            <PrivateRoute
              path="/window/edit/:id"
              component={(props) => <Window {...props} edit={true} />}
            />
            <PrivateRoute
              path="/projectItem/view/:id"
              component={(props) => <ProjectItemDetails {...props} />}
            />
            <PrivateRoute
              path="/productItem/view/:id"
              component={(props) => <ProductItemDetails {...props} />}
            />
            <PrivateRoute
              path="/search"
              component={(props) => <SearchContainer {...props} edit={true} />}
            />

            <PrivateRoute
              path="/checkout/:id"
              component={(props) => <Checkout {...props} />}
            />
            <Route path="/">
              <div>
                <Login />
                <Button component={Link} to="/register" color="primary">
                  Register
                </Button>
              </div>
            </Route>
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}
