import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Button,
  TextField,
  Container,
} from "@material-ui/core";
import { Redirect, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  isLoading,
  getProductItemDetails,
  productItem,
  addItemToRoom,
  addItemToWindow,
} from "./productItemSlice";
import { token } from "../users/usersSlice";
import { shouldRedirect, resetRedirect } from "../redirect/redirectSlice";

export const ProductItemDetails = (props) => {
  const { id } = props.match.params;

  const dispatch = useDispatch();

  const loading = useSelector(isLoading);
  const item = useSelector(productItem);
  const tokenVal = useSelector(token);
  const shouldRedirectVal = useSelector(shouldRedirect);

  const [fieldData, setFieldData] = useState({ quantity: 1 });

  const selectedInput = useRef();

  const { search } = useLocation();
  const { roomId, windowId } = queryString.parse(search);

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  });

  useEffect(() => {
    dispatch(getProductItemDetails({ tokenVal, id }));
  }, [dispatch, tokenVal, id]);

  useEffect(() => {
    return () => {
      dispatch(resetRedirect()); // reset redirect on unmount
    };
  }, [shouldRedirectVal, dispatch]);

  const saveItem = (e) => {
    e.preventDefault();

    if (roomId) {
      dispatch(
        addItemToRoom({
          tokenVal,
          addedItem: {
            ...fieldData,
            item_id: id,
            room_id: roomId,
            name: item.name,
            description: item.description,
          },
        })
      );
    } else if (windowId) {
      dispatch(
        addItemToWindow({
          tokenVal,
          addedItem: {
            ...fieldData,
            item_id: id,
            window_id: windowId,
            name: item.name,
            description: item.description,
          },
        })
      );
    }
  };

  if (shouldRedirectVal === "search") {
    return <Redirect exact to={`/search`} />;
  }

  const renderComponent =
    loading === "pending" || !item ? (
      <CircularProgress />
    ) : (
      <div>
        <h2>{item.name}</h2>
        <span>{item.description}</span>
        <form autoComplete="off" onSubmit={saveItem}>
          <Container>
            <TextField
              variant="outlined"
              margin="normal"
              inputRef={selectedInput}
              label="Quantity"
              type="number"
              defaultValue={1}
              onChange={(e) =>
                setFieldData({ ...fieldData, quantity: e.target.value })
              }
              InputProps={{ inputProps: { min: 1 } }}
            />
          </Container>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            aria-label="Add Item"
          >
            Add Item
          </Button>

          <Button
            variant="contained"
            color="primary"
            aria-label="Back to Search"
          >
            Back to Search
          </Button>
        </form>
      </div>
    );

  return renderComponent;
};
